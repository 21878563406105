// Custom components
  import Card from "components/card/Card.js";
  import CheckTable from "./CheckTable";
  import {columnsDataRoles} from "../variables/columnsData";
  
  // Assets
  import React, { useState, useEffect } from 'react';
  import {useToast} from "@chakra-ui/react";
  //API calls
import { GET_ROLES } from 'api/ApiUtils';
  
  export default function RolesListFunc(props) {
    const { ...rest } = props;
    const toast = useToast();

  const [roleList, setRolesList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [limit, setLimit] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const fetchData = async (data) => {
    let response = await GET_ROLES(data)
    if (response.data?.status === "success"){
      setRolesList(response.data.data.roles.rows)
      const totalPages = Math.ceil(response.data.data.roles.count/limit);
      setNumberOfPages(totalPages)
      props.setRoleCount(response.data.data.roles.count)
    }else if(response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if(response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if(response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }
  
  useEffect(() => {
    async function fetchMyAPI() {
      let data= {};
      data.pageNo = pageNo;
      data.limit = limit;
      await fetchData(data);
    }
    fetchMyAPI()
  }, [pageNo, limit])
  const gotoPage = async (page) => {
    setPageNo(page)
    setLimit(limit)
  }
  const handleLimitChange = async (limit) => {
    setPageNo(0);
    setLimit(limit);
  }
  const gotoNextPage = async (page) => {
    setPageNo(page+1);
    setLimit(limit);
  }
  const gotoPreviousPage = async () => {
    setPageNo(pageNo-1);
    setLimit(limit);
  }

    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}>
        <CheckTable 
          columnsData={columnsDataRoles} 
          tableData={roleList}
          pageNo={pageNo}
          numberOfPages={numberOfPages}
          gotoPage={gotoPage}
          handleLimitChange = {handleLimitChange}
          gotoNextPage= {gotoNextPage}
          gotoPreviousPage= {gotoPreviousPage}
        />
      </Card>
    );
  }
  