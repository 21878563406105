import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Flex,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Link,
  Icon,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import { IoMdEye, IoMdArrowForward } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Modal from "components/Modal";

//API calls
import { ADD_USER_DETAILS, GET_A_USER } from 'api/ApiUtils';

export default function AddStoreForm(props) {
  const toast = useToast()
  const history = useHistory();

  const [isOpenState, setIsOpenState] = useState(false);
  const [user_id, setUserId] = useState('');
  const [username, setUserName] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [national_id, setNationalId] = useState('');
  const [passport_id, setPassportId] = useState('');
  const [phone_number, setPhoneNumber] = useState('');

  const handleUserNameChange = (event) => setUserName(event.target.value);
  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleAvatarChange = (event) => setAvatar(event.target.value);
  const handleNationalIdChange = (event) => setNationalId(event.target.value);
  const handlePassportIdChange = (event) => setPassportId(event.target.value);
  const handlePhoneNumberChange = (event) => setPhoneNumber(event.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      user_id,
      username,
      first_name,
      last_name,
      avatar,
      national_id,
      passport_id,
      phone_number,
      status: 1
    }

    let response = await ADD_USER_DETAILS(data)

    if (response.data?.status === "success") {
      history.push({ pathname: '/admin/my-profile' })
    } else if(response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if(response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if(response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  // Chakra color mode
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  useEffect(() => {
    const unlisten = history.listen(async () => {
      console.log('User is leaving the page');
      setIsOpenState(true);
      // Do something before the user leaves the page
      let data = {};
      let userData = JSON.parse(localStorage.getItem('user'))
        data = {
          user_id: userData.id
        }
      let response = await GET_A_USER(data)
        if(!response.data.data.user.user_details.id) {
          setIsOpenState(true);
        }
    });

    return () => {
      console.log('Component is unmounting');
      unlisten();
      // Do something before the component unmounts
    };
  }, [history]);

  useEffect(() => {
    async function fetchMyAPI() {
      let data = {};
      let userData = JSON.parse(localStorage.getItem('user'))
      setUserId(userData.id);
    }
    fetchMyAPI()
  }, [])

  const handleModalClick = () => {
    setIsOpenState(!isOpenState)
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
        />
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          {props.props?.location?.state?.action === "edit" ? "Edit User" : "Add User Details"}
        </Text>
        <Link
          w='100%'
          href='#/admin/my-profile'>
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={IoMdArrowForward} color={brandColor} w='24px' h='24px' />}
          />
        </Link>
      </Flex>
      <Flex
        zIndex='2'
        direction='column'
        alignItems='center'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}>
        <FormControl isRequired={true}>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Username<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input your desired username here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='username'
            onChange={handleUserNameChange}
            value={username}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            First name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input your first name here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='first_name'
            onChange={handleFirstNameChange}
            value={first_name}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Last name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input your last name here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='last_name'
            onChange={handleLastNameChange}
            value={last_name}
          />

          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Avatar<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input your avatar link here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='avatar'
            onChange={handleAvatarChange}
            value={avatar}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Phone Number<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='number'
            placeholder='254706XXXXXX'
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={handlePhoneNumberChange}
            value={phone_number}
          />
          
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            National ID Number<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='number'
            placeholder='32121212'
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={handleNationalIdChange}
            value={national_id}
          />

          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Passport ID Number<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='KJF54546TG'
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={handlePassportIdChange}
            value={passport_id}
          />

          <Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmit}
          >
            {props.props?.location?.state?.action === "edit" ? "Edit" : "Submit"}

          </Button>
          <Modal
            open={isOpenState}
            handleClick={handleModalClick}
            modalTitle="More Details Required"
            modalBody="Please provide more information about you to proceed"
            modolButtonText='Proceed to form'
          />
        </FormControl>
      </Flex>
    </Card>
  );
}


