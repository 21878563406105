import React from "react";

// Chakra imports
import { Button, Flex, Text } from "@chakra-ui/react";

// Assets
import banner from "assets/img/site/shopbanner1.jpg";

export default function Banner() {
  return (
    <Flex
      direction='column'
      bgImage={banner}
      style={{filter: "brightness(50%)"}}
      bgSize='cover'
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius='30px'>
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color='white'
        mb='14px'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "46%",
          xl: "70%",
          "2xl": "50%",
          "3xl": "42%",
        }}
        fontWeight='700'
        lineHeight={{ base: "32px", md: "42px" }}>
        Discover and buy quality Cereals affodably!
      </Text>
      <Text
        fontSize='md'
        color='#E3DAFF'
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight='500'
        mb='40px'
        lineHeight='28px'>
        Get quality affordably with zero hustles.
        Finally!
      </Text>
      <Flex align='center'>
      <a href="https://www.greenlandcommodities.africa/join-us" target="_blank" rel="noopener noreferrer">
      <Button
          bg='white'
          color='black'
          _hover={{ bg: "whiteAlpha.900" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
          fontWeight='500'
          fontSize='14px'
          py='20px'
          px='27'
          me='38px'>
          Join us now!
        </Button>
      </a>
        
        {/* <Link>
          <Text color='white' fontSize='sm' fontWeight='500'>
            Watch video
          </Text>
        </Link> */}
      </Flex>
    </Flex>
  );
}
