import React, { useState } from 'react';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/site/shopGreenLand/components/Banner";
import TableTopCreators from "views/site/shopGreenLand/components/TableTopCreators";
import HistoryItem from "views/site/shopGreenLand/components/HistoryItem";
import Product from "components/card/Product";
import Card from "components/card/Card.js";
import Modal from "components/Modal";

// Assets
import tableDataTopCreators from "views/site/shopGreenLand/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/site/shopGreenLand/variables/tableColumnsTopCreators";
import ImageURLs from '../../../assets/img';

export default function Marketplace() {
  const [isOpenState, setIsOpenState] = useState(false);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const handleModalClick = (e) => {
    e.preventDefault();
    setIsOpenState(!isOpenState);
  }

  return (
    <Box pt={{ base: "50px", md: "50px", xl: "50px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Banner />
          <Flex direction='column'>
            <Flex
              mt='45px'
              mb='20px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                Best Sellers
              </Text>
              <Flex
                align='center'
                me='20px'
                ms={{ base: "24px", md: "0px" }}
                mt={{ base: "20px", md: "0px" }}>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#art'>
                  Beans
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#music'>
                  Rice
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#collectibles'>
                  Grains
                </Link>
                <Link color={textColorBrand} fontWeight='500' href='#recent'>
                  Recent
                </Link>
              </Flex>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
              <Product
                name='Yellow Beans'
                author='By Greenland CA'
                image={ImageURLs.yellowBeansUrl}
                currentbid='160'
                download='#'
                handleClick={handleModalClick}
              />
              <Product
                name='Lentils/Kamande'
                author='By Greenland CA'
                image={ImageURLs.lentilsUrl}
                currentbid='310'
                download='#'
                handleClick={handleModalClick}
              />
              <Product
                name='Greengrams/Makueni '
                author='By Greenland CA'
                image={ImageURLs.makueniUrl}
                currentbid='100'
                download='#'
                handleClick={handleModalClick}
              />
            </SimpleGrid>
            <section id='recent'>
            <Text
              mt='45px'
              mb='36px'
              color={textColor}
              fontSize='2xl'
              ms='24px'
              fontWeight='700'>
              Recently Added Cereals
            </Text>
            </section>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              gap='20px'
              mb={{ base: "20px", xl: "0px" }}>
              <Product
                name='Green Army'
                author='By Greenland CA'
                image={ImageURLs.greenArmyUrl}
                currentbid='185'
                download='#'
                handleClick={handleModalClick}
              />
              <Product
                name='Lablab/Njahi'
                author='By Greenland CA'
                image={ImageURLs.njahiUrl}
                currentbid='195'
                download='#'
                handleClick={handleModalClick}
              />
              <Product
                name='Maize'
                author='By Greenland CA'
                image={ImageURLs.maizeUrl}
                currentbid='55'
                download='#'
                handleClick={handleModalClick}
              />
            </SimpleGrid>
          </Flex>
        </Flex>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
            <TableTopCreators
              tableData={tableDataTopCreators}
              columnsData={tableColumnsTopCreators}
            />
          </Card>
          {/* <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
                Top Loosers
              </Text>
              <Button variant='action'>See all</Button>
            </Flex>

            <HistoryItem
              name='Colorful Heaven'
              author='By Greenland CA'
              date='30s ago'
              image={Nft5}
              price='0.91 ETH'
            />
            <HistoryItem
              name='Abstract Colors'
              author='By Greenland CA'
              date='58s ago'
              image={Nft1}
              price='0.91 ETH'
            />
            <HistoryItem
              name='ETH AI Brain'
              author='By Greenland CA'
              date='1m ago'
              image={Nft2}
              price='0.91 ETH'
            />
            <HistoryItem
              name='Swipe Circles'
              author='By Greenland CA'
              date='1m ago'
              image={Nft4}
              price='0.91 ETH'
            />
            <HistoryItem
              name='Mesh Gradients '
              author='By Greenland CA'
              date='2m ago'
              image={Nft3}
              price='0.91 ETH'
            />
            <HistoryItem
              name='3D Cubes Art'
              author='By Greenland CA'
              date='3m ago'
              image={Nft6}
              price='0.91 ETH'
            />
          </Card> */}
        </Flex>
      </Grid>
      <Modal
        open={isOpenState}
        handleClick={handleModalClick}
        modalTitle="Dial 0758657833 to place order"
        modalBody="Thank you for taking interest"
        modolButtonText='Close dialog'
      />
    </Box>
  );
}
