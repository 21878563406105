export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataStores = [
  {
    Header: "STORE NAME",
    accessor: "name",
  },
  {
    Header: "STORE LOCATION",
    accessor: "location",
  },
  {
    Header: "OWNER ID",
    accessor: "user_id",
  },{
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
