export const columnsDataCustomers = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "phone_number",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },{
    Header: "LEAD",
    accessor: "lead",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
