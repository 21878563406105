import logo from './logos/logo.png';

const ImageURLs = {
    logoUrl: logo,
    maizeUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlxKb0BI8oWt5oGCLxPY1OzFvGvexRqWteuA&usqp=CAU',
    lentilsUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBHBjMQKmxcStyXqflPclled6_Bp52bhL27A&usqp=CAU',
    makueniUrl: 'https://www.bettagrains.com/wp-content/uploads/2013/10/DenguUncle2.jpg',
    yellowBeansUrl: 'https://www.fmk.co.ke/wp-content/uploads/2021/09/IMG_-p3sfbf-482x410.jpg',
    greenArmyUrl: 'https://s3.eu-west-1.amazonaws.com/s.keekapu.com/army-green-beansJLKp.jpg',
    nylonUrl: 'https://graduatefarmer.co.ke/wp-content/uploads/2017/05/Green-gram.jpg',
    njahiUrl: 'https://africamarket.us/cdn/shop/products/3169188718.jpg?v=1674767501',
    wairimuUrl: '',
    mbaaziUrl: '',
    muthokoiUrl: '',
    popcornsUrl: 'https://cdn.wikifarmer.com/wp-content/uploads/2022/04/maize-nutritional-value.jpg',
    blackButterBeansUrl: 'https://graduatefarmer.co.ke/wp-content/uploads/2017/05/Green-gram.jpg'
}
  
  export default ImageURLs;