// Custom components
import Card from "components/card/Card.js";
import CheckTable from "./CheckTable";
import { columnsDataUsers } from "../variables/columnsData";

// Assets
import React, { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";
//API calls
import { UserList } from 'api/ApiUtils';
// import {LINK_DIRECTORY_RESPONSE} from 'api/dummyResponses'

export default function SiteListFunc(props) {
  const { ...rest } = props;
  const toast = useToast();

  const [usersList, setUsersList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [limit, setLimit] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const fetchData = async (data) => {
    let userData = JSON.parse(localStorage.getItem('user'))
    data.user_id = userData.id
    let response = await UserList(data)
    if (response.data?.status === "success") {
      setUsersList(response.data.data.users.rows)
      const totalPages = Math.ceil(response.data.data.users.count / limit);
      setNumberOfPages(totalPages)
      // props.setProductSaleCount(response.data.data.users.count)
    } else if (response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if (response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if (response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }
  useEffect(() => {
    async function fetchMyAPI() {
      let data = {};
      data.pageNo = pageNo;
      data.limit = limit;
      await fetchData(data);
    }
    fetchMyAPI()
  }, [pageNo, limit])
  const gotoPage = async (page) => {
    setPageNo(page)
    setLimit(limit)
  }
  const handleLimitChange = async (limit) => {
    setPageNo(0);
    setLimit(limit);
  }
  const gotoNextPage = async (page) => {
    setPageNo(page + 1);
    setLimit(limit);
  }
  const gotoPreviousPage = async () => {
    setPageNo(pageNo - 1);
    setLimit(limit);
  }
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <CheckTable
        columnsData={columnsDataUsers}
        tableData={usersList}
        pageNo={pageNo}
        numberOfPages={numberOfPages}
        gotoPage={gotoPage}
        handleLimitChange={handleLimitChange}
        gotoNextPage={gotoNextPage}
        gotoPreviousPage={gotoPreviousPage}
      />
    </Card>
  );
}
