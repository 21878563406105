import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Flex,
  Button,
  Text,
  Select,
  Link,
  FormControl,
  FormLabel,
  Input,
  Icon,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import { IoMdEye, IoMdArrowForward } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Modal from "components/Modal";

  //API calls
  import { ADD_PRODUCT, GET_PRODUCT_CATEGORIES, GET_METRIC_UNITS } from 'api/ApiUtils';

export default function AddStoreForm(props) {
  const toast = useToast();
  const history = useHistory();

  const [isOpenState, setIsOpenState] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [productCategoryId, setProductCategoryId] = useState('');
  const [productCategoryName, setProductCategoryName] = useState('');
  const [productCategories, setProductCategories] = useState([]);
  const [unitMetricId, setUnitMetricId] = useState('');
  const [unitMetricName, setUnitMetricName] = useState('');
  const [unitMetrics, setUnitMetrics] = useState([]);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await GET_PRODUCT_CATEGORIES();
      setProductCategories(response.data.data.product_categories.rows);

      response = await GET_METRIC_UNITS();
      console.log(response);
      setUnitMetrics(response.data.data.metrics.rows);
      
    }
    fetchMyAPI()
  }, [])

  const handleNameChange = (event) => setName(event.target.value);
  const handleDescriptionChange = (event) => setDescription(event.target.value);
  const handleCategoryIdChange = (event) => {
    setProductCategoryId(event.target.value.split('-')[0]);
    setProductCategoryName(event.target.value); 
  }
  const handleUnitMetricIdChange = (event) => {
    setUnitMetricId(event.target.value.split('-')[0]);
    setUnitMetricName(event.target.value); 
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let data = {
      name,
      description,
      category_id: productCategoryId,
      unit: unitMetricId,
      status: 1
    }

    let response = await ADD_PRODUCT(data)
  
    if(response.data?.status === "success") {
      history.push({pathname:'/admin/products'})
    }else if(response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if(response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if(response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  // Chakra color mode
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const handleModalClick = () => {

    setIsOpenState(!isOpenState)
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
        />
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          {props.props?.location?.state?.action === "edit" ? "Edit Product" : "Add a Product"}
        </Text>
        <Link
          w='100%'
          href='#/admin/products'>  
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={IoMdArrowForward} color={brandColor} w='24px' h='24px' />}
          />
          </Link>
      </Flex>
      <Flex
        zIndex='2'
        direction='column'
        alignItems='center'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}>
        <FormControl isRequired={true}>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input the name of the product here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='siteName'
            onChange={handleNameChange}
            value={name}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Description<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input the description here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='siteName'
            onChange={handleDescriptionChange}
            value={description}
          />
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select category<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={productCategoryName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleCategoryIdChange} >
              <option value={null} >Select</option>
              {productCategories.map((category,i) => {
                return <option value={`${category.id}-${category.name}`} id={i}>{category.name}</option>
              })}
          </Select>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Unit of Measurement<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={unitMetricName}
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' onChange={handleUnitMetricIdChange} >
              <option value={null} >Select</option>
              {unitMetrics.map((unit,i) => {
                return <option value={`${unit.id}-${unit.name}`} id={i}>{unit.name}</option>
              })}
          </Select>

          <Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmit}
          >
            {props.props?.location?.state?.action === "edit" ? "Edit" : "Submit"}

          </Button>
          <Modal
            open={isOpenState}
            handleClick={handleModalClick}
            modalTitle="Success"
            modalBody="The link has been submited!"
          />
        </FormControl>
      </Flex>
    </Card>
  );
}


