export const columnsDataSuppliers = [
  {
    Header: "SUPPLIER NAME",
    accessor: "name",
  },
  {
    Header: "SUPPLIER LOCATION",
    accessor: "location",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "phone_number",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
