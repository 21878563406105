import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Flex,
  Button,
  Text,
  Select,
  FormControl,
  FormLabel,
  Link,
  Icon,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import { IoMdEye, IoMdArrowForward } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Modal from "components/Modal";

  //API calls
  import { GET_ROLES,GET_USERS,GET_STORES,ADD_ROLE_USER} from 'api/ApiUtils';

export default function AddProductRestockForm(props) {
  const toast = useToast();
  const history = useHistory();

  const [isOpenState, setIsOpenState] = useState(false);
  const [userName, setUserName] = useState('');
  const [user_id, setUserId] = useState('');
  const [storeName, setStoreName] = useState('');
  const [store_id, setStoreId] = useState('');
  const [roleName, setRoleName] = useState('');
  const [role_id, setRoleId] = useState('');
  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await GET_USERS();
      setUsers(response.data.data.users.rows);

      response = await GET_STORES();
      setStores(response.data.data.stores.rows);

      response = await GET_ROLES();
      setRoles(response.data.data.roles.rows);
      
    }
    fetchMyAPI()
  }, [])

  const handleUserIdChange = (event) => {
    setUserId(event.target.value.split('-')[0]);
    setUserName(event.target.value);
  }
  const handleStoreIdChange = (event) => {
    setStoreId(event.target.value.split('-')[0]);
    setStoreName(event.target.value);
  }
  const handleRoleIdChange = (event) => {
    setRoleId(event.target.value.split('-')[0]);
    setRoleName(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let data = {
      user_id,
      store_id,
      role_id,
      status: 1
    }

    let response = await ADD_ROLE_USER(data);
    if(response.data?.status === "success") {
      history.push({pathname:'/admin/role-users'})
    }else if(response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if(response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if(response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  // Chakra color mode
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const handleModalClick = () => {

    setIsOpenState(!isOpenState)
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
        />
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          {props.props?.location?.state?.action === "edit" ? "Edit Role User" : "Add a Role User"}
        </Text>
        <Link
          w='100%'
          href='#/admin/role-users'>  
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={IoMdArrowForward} color={brandColor} w='24px' h='24px' />}
          />
          </Link>
      </Flex>
      <Flex
        zIndex='2'
        direction='column'
        alignItems='center'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}>
        <FormControl isRequired={true}>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select User<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={userName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleUserIdChange} >
              <option value={null} >Select</option>
              {users.map((user,i) => {
                return <option value={`${user.id}-${user.email}`} id={i}>{user.email}</option>
              })}
          </Select>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Store<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={storeName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleStoreIdChange} >
              <option value={null} >Select</option>
              {stores.map((store,i) => {
                return <option value={`${store.id}-${store.name}`} id={i}>{store.name}</option>
              })}
          </Select>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Role<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={roleName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleRoleIdChange} >
              <option value={null} >Select</option>
              {roles.map((role,i) => {
                return <option value={`${role.id}-${role.name}`} id={i}>{role.name}</option>
              })}
          </Select>

          <Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmit}
          >
            {props.props?.location?.state?.action === "edit" ? "Edit" : "Submit"}

          </Button>
          <Modal
            open={isOpenState}
            handleClick={handleModalClick}
            modalTitle="Success"
            modalBody="The link has been submited!"
          />
        </FormControl>
      </Flex>
    </Card>
  );
}


