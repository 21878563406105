import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdSettings,
  MdOutlineShoppingCart,
  MdPeople,
  MdShop,
  MdAddLink,
  MdFireTruck,
  MdFolderOpen,
  MdBreakfastDining,
  MdShoppingBasket,
  MdWoman2,
  MdScale,
  MdMonetizationOn,
  MdAccountBox,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import Dashboard from "views/admin/dashboard";
import MyProfile from "views/admin/myProfile2";
import Shops from "views/admin/shops";
import Loans from "views/admin/loans";
import Reports from "views/admin/reports";
import ShopAccount from "views/admin/shopaccount";
import SearchResults from "views/admin/searchresults";
import Account from "views/admin/account";
import AddSites from "views/admin/addSite";
import AddStores from "views/admin/addStore";
import AddEmployees from "views/admin/addEmployee";
import AddSuppliers from "views/admin/addSupplier";
import AddProductCategories from "views/admin/addProductCategory";
import AddProducts from "views/admin/addProduct";
import AddProductRestocks from "views/admin/addProductRestock";
import AddProductSales from "views/admin/addProductSale";
import AddCustomers from "views/admin/addCustomer";
import AddTransactions from "views/admin/addTransaction";
import AddUnitMetrics from "views/admin/addUnitMetric";
import AddUsers from "views/admin/addUser";
import AddRoles from "views/admin/addRole";
import AddRoleUsers from "views/admin/addRoleUser";
import UsersList from "views/admin/usersList";
import BusinessLeadsList from "views/admin/businessLeadsList";
import Stores from "views/admin/listStores";
import Products from "views/admin/listProducts";
import ProductRestocks from "views/admin/listProductRestocks";
import ProductSales from "views/admin/listProductSales";
import ProductCategories from "views/admin/listProductCategories";
import Suppliers from "views/admin/listSuppliers";
import Customers from "views/admin/listCustomers";
import Transactions from "views/admin/listTransactions";
import UnitMetrics from "views/admin/listUnitMetrics";
import Employees from "views/admin/listEmployees";
import Roles from "views/admin/listRoles";
import RoleUsers from "views/admin/listRoleUsers";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import ForgotPassword from "views/auth/forgotPassword";
import ResetPassword from "views/auth/resetPassword";
import VerifyEmail from "views/auth/verifyEmail";
import ResendVerifyEmail from "views/auth/resendEmailVerification";

// Shop Imports
import Shop from "views/site/shopGreenLand";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Dashboard,
    show: false
  },  
  {
    name: "Reports",
    layout: "/admin",
    path: "/reports",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Reports,
    show: true
  },
  {
    name: "Add Sites",
    layout: "/admin",
    path: "/add-sites",
    icon: <Icon as={MdAddLink} width='20px' height='20px' color='inherit' />,
    component: AddSites,
    show: false
  },
  {
    name: "Add Stores",
    layout: "/admin",
    path: "/add-stores",
    icon: <Icon as={MdShop} width='20px' height='20px' color='inherit' />,
    component: AddStores,
    show: false
  },
  {
    name: "Stores",
    layout: "/admin",
    path: "/stores",
    icon: <Icon as={MdShop} width='20px' height='20px' color='inherit' />,
    component: Stores,
    show: true
  },
  {
    name: "Add Employees",
    layout: "/admin",
    path: "/add-employees",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: AddEmployees,
    show: false
  },
  {
    name: "Employees",
    layout: "/admin",
    path: "/employees",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: Employees,
    show: true
  },
  {
    name: "Add Suppliers",
    layout: "/admin",
    path: "/add-suppliers",
    icon: <Icon as={MdFireTruck} width='20px' height='20px' color='inherit' />,
    component: AddSuppliers,
    show: false
  },
  {
    name: "Suppliers",
    layout: "/admin",
    path: "/suppliers",
    icon: <Icon as={MdFireTruck} width='20px' height='20px' color='inherit' />,
    component: Suppliers,
    show: true
  },
  {
    name: "Add Product Categories",
    layout: "/admin",
    path: "/add-product-categories",
    icon: <Icon as={MdFolderOpen} width='20px' height='20px' color='inherit' />,
    component: AddProductCategories,
    show: false
  },
  {
    name: "Product Categories",
    layout: "/admin",
    path: "/product-categories",
    icon: <Icon as={MdFolderOpen} width='20px' height='20px' color='inherit' />,
    component: ProductCategories,
    show: true
  },
  {
    name: "Add Products",
    layout: "/admin",
    path: "/add-products",
    icon: <Icon as={MdBreakfastDining} width='20px' height='20px' color='inherit' />,
    component: AddProducts,
    show: false
  },
  {
    name: "Products",
    layout: "/admin",
    path: "/products",
    icon: <Icon as={MdBreakfastDining} width='20px' height='20px' color='inherit' />,
    component: Products,
    show: true
  },
  {
    name: "Add Product Restocks",
    layout: "/admin",
    path: "/add-product-restocks",
    icon: <Icon as={MdShoppingBasket} width='20px' height='20px' color='inherit' />,
    component: AddProductRestocks,
    show: false
  },
  {
    name: "Product Restocks",
    layout: "/admin",
    path: "/product-restocks",
    icon: <Icon as={MdShoppingBasket} width='20px' height='20px' color='inherit' />,
    component: ProductRestocks,
    show: true
  },
  {
    name: "Add Product Sales",
    layout: "/admin",
    path: "/add-product-sales",
    icon: <Icon as={MdScale} width='20px' height='20px' color='inherit' />,
    component: AddProductSales,
    show: false
  },
  {
    name: "Product Sales",
    layout: "/admin",
    path: "/product-sales",
    icon: <Icon as={MdScale} width='20px' height='20px' color='inherit' />,
    component: ProductSales,
    show: true
  },
  {
    name: "Add Customers",
    layout: "/admin",
    path: "/add-customers",
    icon: <Icon as={MdWoman2} width='20px' height='20px' color='inherit' />,
    component: AddCustomers,
    show: false
  },{
    name: "Customers",
    layout: "/admin",
    path: "/customers",
    icon: <Icon as={MdWoman2} width='20px' height='20px' color='inherit' />,
    component: Customers,
    show: true
  },
  {
    name: "Add Transactions",
    layout: "/admin",
    path: "/add-transactions",
    icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
    component: AddTransactions,
    show: false
  },{
    name: "Transactions",
    layout: "/admin",
    path: "/transactions",
    icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
    component: Transactions,
    show: true
  },
  {
    name: "Add Unit Metrics",
    layout: "/admin",
    path: "/add-unit-metrics",
    icon: <Icon as={MdScale} width='20px' height='20px' color='inherit' />,
    component: AddUnitMetrics,
    show: false
  },
  {
    name: "Unit Metrics",
    layout: "/admin",
    path: "/unit-metrics",
    icon: <Icon as={MdScale} width='20px' height='20px' color='inherit' />,
    component: UnitMetrics,
    show: true
  },
  {
    name: "Add Roles",
    layout: "/admin",
    path: "/add-roles",
    icon: <Icon as={MdAccountBox} width='20px' height='20px' color='inherit' />,
    component: AddRoles,
    show: false
  },
  {
    name: "Roles",
    layout: "/admin",
    path: "/roles",
    icon: <Icon as={MdAccountBox} width='20px' height='20px' color='inherit' />,
    component: Roles,
    show: true
  },
  {
    name: "Add Role Users",
    layout: "/admin",
    path: "/add-role-users",
    icon: <Icon as={MdAccountBox} width='20px' height='20px' color='inherit' />,
    component: AddRoleUsers,
    show: false
  },  
  {
    name: "Role Users",
    layout: "/admin",
    path: "/role-users",
    icon: <Icon as={MdAccountBox} width='20px' height='20px' color='inherit' />,
    component: RoleUsers,
    show: true
  },
  {
    name: "My Profile",
    layout: "/admin",
    path: "/my-profile",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: MyProfile,
    show: true
  },
  {
    name: "Add Users",
    layout: "/admin",
    path: "/add-users",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: AddUsers,
    show: false
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: UsersList,
    show: true
  },
  {
    name: "Business Leads",
    layout: "/admin",
    path: "/business_leads",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: BusinessLeadsList,
    show: true
  },

  ////////////////////////////////////////////////////////
  /////// OLD ROUTES
  ////////////////////////////////////////////////////////
  {
    name: "Shops",
    layout: "/admin",
    path: "/shops",
    icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
    component: Shops,
    show: false
  },
  {
    name: "Loans",
    layout: "/admin",
    path: "/loans",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: Loans,
    show: false
  },  

  {
    name: "Shop Account",
    layout: "/admin",
    path: "/single-shop-view",
    icon: <Icon as={MdShop} width='20px' height='20px' color='inherit' />,
    show: false,
    component: ShopAccount,
  },  
  {
    name: "Search Results",
    layout: "/admin",
    path: "/search-results",
    icon: <Icon as={MdShop} width='20px' height='20px' color='inherit' />,
    show: false,
    component: SearchResults,
  },
  {
    name: "Account",
    layout: "/admin",
    path: "/account",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Account,
    show: false
  },
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    show: false
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
    show: false
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables,
    show: false
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    show: false
  },

  ////////////////////////////////////////////
  //// AUTH VIEWS
  //////////////////////////////////////
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    show: false
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignUpCentered,
    show: false
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ForgotPassword,
    show: false
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResetPassword,
    show: false
  },
  {
    name: "Verify Email",
    layout: "/auth",
    path: "/verify-email",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: VerifyEmail,
    show: false
  },
  {
    name: "Resend Verify Email",
    layout: "/auth",
    path: "/resend-verify-email",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResendVerifyEmail,
    show: false
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "/rtl-default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: RTL,
    show: false
  },
  ///////////////////////////////////////////////////////////////\
  //////SHOP ROUTES
  ///////////////////////////////////////////\
  {
    name: "Shop",
    layout: "/site",
    path: "/shop",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Shop,
    secondary: true,
    show: true
  },
];

export default routes;
