// Chakra imports
import {
  Box,
  SimpleGrid
} from "@chakra-ui/react";

// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";

import React, { useState, useEffect } from 'react';

import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import MiniStatisticsComponent from "./components/MiniStatistics"


import { GET_REPORTS } from 'api/ApiUtils';


export default function UserReports() {
  const [totalProfits, setTotalProfits] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesQuantity, setTotalSalesQuantity] = useState(0);


  useEffect(() => {
    async function fetchMyAPI() {

      let response = await GET_REPORTS();
      const reports = response.data.data.reports;
      setTotalProfits(reports.profitSum);
      setTotalSales(reports.totalCount);
      setTotalSalesQuantity(reports.totalQuantitySold);

    }

    fetchMyAPI()
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <MiniStatisticsComponent
        totalProfits = {totalProfits}
        totalSalesQuantity = {totalSalesQuantity}
        totalSales = {totalSales}

      />

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
}
