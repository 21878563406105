import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Flex,
  Button,
  Text,
  Select,
  FormControl,
  FormLabel,
  Input,
  Link,
  Icon,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import { IoMdEye, IoMdArrowForward } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Modal from "components/Modal";

  //API calls
  import { GET_PRODUCTS,GET_STORES,GET_SUPPLIERS,ADD_PRODUCT_RESTOCK} from 'api/ApiUtils';

export default function AddProductRestockForm(props) {
  const toast = useToast();
  const history = useHistory();

  const [isOpenState, setIsOpenState] = useState(false);
  const [productName, setProductName] = useState('');
  const [product_id, setProductId] = useState('');
  const [storeName, setStoreName] = useState('');
  const [store_id, setStoreId] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [supplier_id, setSupplierId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price_per_unit, setPricePerUnit] = useState('');
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [restock_date, setRestockDate] = useState('');

  useEffect(() => {
    async function fetchMyAPI() {
      let data = {};
      data.limit = 50;
      let response = await GET_PRODUCTS(data);
      setProducts(response.data.data.products.rows);

      response = await GET_STORES();
      setStores(response.data.data.stores.rows);

      response = await GET_SUPPLIERS();
      setSuppliers(response.data.data.suppliers.rows);
      
    }
    fetchMyAPI()
  }, [])

  const handleQuantityChange = (event) => setQuantity(event.target.value);
  const handlePricePerUnitChange = (event) => setPricePerUnit(event.target.value);
  const handleRestockDateChange = (event) => setRestockDate(event.target.value);
  const handleProductIdChange = (event) => {
    setProductId(event.target.value.split('-')[0]);
    setProductName(event.target.value);
  }
  const handleStoreIdChange = (event) => {
    setStoreId(event.target.value.split('-')[0]);
    setStoreName(event.target.value);
  }
  const handleSupplierIdChange = (event) => {
    setSupplierId(event.target.value.split('-')[0]);
    setSupplierName(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let data = {
      product_id,
      store_id,
      supplier_id,
      quantity,
      price_per_unit,
      restock_date,
      status: 1
    }

    let response = await ADD_PRODUCT_RESTOCK(data);
    if(response.data?.status === "success") {
      history.push({pathname:'/admin/product-restocks'})
    }else if(response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if(response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if(response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  // Chakra color mode
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const handleModalClick = () => {

    setIsOpenState(!isOpenState)
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
        />
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          {props.props?.location?.state?.action === "edit" ? "Edit Product Restock" : "Add a Prodct Restock"}
        </Text>
        <Link
          w='100%'
          href='#/admin/product-restocks'>  
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={IoMdArrowForward} color={brandColor} w='24px' h='24px' />}
          />
          </Link>
      </Flex>
      <Flex
        zIndex='2'
        direction='column'
        alignItems='center'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}>
        <FormControl isRequired={true}>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Product<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={productName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleProductIdChange} >
              <option value={null} >Select</option>
              {products.map((product,i) => {
                return <option value={`${product.id}-${product.name}`} id={i}>{product.name}</option>
              })}
          </Select>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Store<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={storeName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleStoreIdChange} >
              <option value={null} >Select</option>
              {stores.map((store,i) => {
                return <option value={`${store.id}-${store.name}`} id={i}>{store.name}</option>
              })}
          </Select>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Supplier<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={supplierName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleSupplierIdChange} >
              <option value={null} >Select</option>
              {suppliers.map((supplier,i) => {
                return <option value={`${supplier.id}-${supplier.name}`} id={i}>{supplier.name}</option>
              })}
          </Select>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Restock Date<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='date'
            placeholder='Input the date of the restock here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='restock_date'
            onChange={handleRestockDateChange}
            value={restock_date}
          />
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Quantity<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='number'
            placeholder='Input the quantity to restock here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='quantity'
            onChange={handleQuantityChange}
            value={quantity}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Price per Unit<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input the price per unit here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='pricePerUnit'
            onChange={handlePricePerUnitChange}
            value={price_per_unit}
          />

          <Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmit}
          >
            {props.props?.location?.state?.action === "edit" ? "Edit" : "Submit"}

          </Button>
          <Modal
            open={isOpenState}
            handleClick={handleModalClick}
            modalTitle="Success"
            modalBody="The link has been submited!"
          />
        </FormControl>
      </Flex>
    </Card>
  );
}


